import React from "react"
import Seo from "../../../components/seo"

import ClimaticChambers from "./climatic-chambers"


const ClimaticChambersIndex = () => (

  <>
    <Seo 
      title="Климатическая камера для созревания и хранения"
      description="Климатические камры созревания сыра, колбас под ключ. Климатическая камера хранения медикаментов."
      keywords="климатическая камера, камера созревания, климат для медикаментов"
    />
    <ClimaticChambers />
  </>
  
)

export default ClimaticChambersIndex